<app-banner [dataHome]="dataHome"></app-banner>
<app-section-meta id="section-meta"></app-section-meta>
<app-section-channels id="section-channels"></app-section-channels>
<app-section-stores
  [dataHome]="dataHome"
  id="section-stores"
></app-section-stores>
<app-section-thanks-you id="section-thanks-you"></app-section-thanks-you>
<app-section-rinde id="section-rinde"></app-section-rinde>
<app-section-stories
  [dataHome]="dataHome"
  id="section-stories"
></app-section-stories>
<app-section-gratitude
  [dataHome]="dataHome"
  id="section-gratitude"
></app-section-gratitude>

<a
  href="/donativo/donar-ahora"
  class="floating-button initial text-[24px] font-quicksand font-bold no-underline box-shadow-left"
>
  ¡Donar Ahora!
</a>

<app-footer-info />
<app-footer />
