import { Routes } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { DonationComponent } from "./pages/donation/donation.component";
import { ThanksYouComponent } from "./pages/thanks-you/thanks-you.component";
import { ErrorComponent } from "./pages/error/error.component";

export const routes: Routes = [
  {
    path: "",
    title: "Teletón nos une - Teleton peru",
    component: HomeComponent,
    data: { showFooter: true },
  },
  {
    path: "donativo/donar-ahora",
    title: "Teletón - Donación",
    component: DonationComponent,
    data: { showFooter: false },
  },
  {
    path: "gracias",
    title: "Teletón - Muchas Gracias",
    component: ThanksYouComponent,
    data: { showFooter: false },
  },
  {
    path: "error",
    title: "Teletón - Error",
    component: ErrorComponent,
    data: { showFooter: false },
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
    component: HomeComponent,
    data: { showFooter: true },
  },
];
