import { Component } from "@angular/core";
import { SharedDataService } from "../../shared/shared-data.service";

@Component({
  selector: "app-footer",
  standalone: true,
  imports: [],
  templateUrl: "./footer.component.html",
  styleUrl: "./footer.component.css",
})
export class FooterComponent {
  public footerData: any;

  constructor(private sharedDataService: SharedDataService) {}

  ngOnInit(): void {
    this.sharedDataService.currentData.subscribe((data) => {
      this.footerData = data;
    });
  }
}
