import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { DeviceDetectorComponent } from '../../../components/device/device.component';
import { ModalService } from '../../../components/modal/modal.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, DeviceDetectorComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent implements OnInit {
  isMobile: boolean = false;
  activeTab: string | null = null;
  isMenuOpen = false;
  isSticky = false;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.onScroll();
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    const stickyOffset = 100;
    this.isSticky = window.pageYOffset > stickyOffset;

    const sections = [
      'section-quienes-somos',
      'section-iniciativas',
      'section-noticias',
    ];
    for (let i = 0; i < sections.length; i++) {
      const sectionId = sections[i];
      const element = document.getElementById(sectionId);
      if (element) {
        const rect = element.getBoundingClientRect();
        if (
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2
        ) {
          this.activeTab = sectionId;
          break;
        }
      }
    }
  }

  scrollToSection(sectionId: string): void {
    const element = document.getElementById(sectionId);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = this.isMobile
        ? elementPosition - 40
        : elementPosition - 100;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });

      this.activeTab = sectionId;
      this.isMenuOpen = false;
    }

    this.modalService.closeModal();
  }

  isActive(tabId: string): boolean {
    return this.activeTab === tabId;
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  onDeviceChange(isMobile: boolean) {
    this.isMobile = isMobile;
    console.log('this mobile: ' + isMobile);
  }
}
