import { Component, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { HeaderComponent } from "./components/header/header.component";
import { SharedDataService } from "./shared/shared-data.service";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-layout",
  standalone: true,
  imports: [HeaderComponent, RouterOutlet, CommonModule],
  templateUrl: "./layout.component.html",
})
export class LayoutComponent implements OnInit {
  public data: any;

  constructor(private sharedDataService: SharedDataService) {}

  ngOnInit(): void {
    this.sharedDataService.currentData.subscribe((data) => {
      this.data = data;
    });
  }
}
