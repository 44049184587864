<div class="relative py-[40px] bg-[#E01319] footer-info">
  <div class="max-w-screen-medium mx-auto">
    <div class="md:flex block gap-[20px] font-quicksand md:px-0 px-[20px]">
      <div class="md:w-1/3 w-full text-center md:mb-0 mb-[40px]">
        <img
          [src]="footerData && footerData.footer_logo"
          class="w-[194px] mb-[20px]"
        />
        <div class="w-[190px] font-quicksand mx-auto">
          <a
            href="/donativo/donar-ahora"
            class="block p-[18px] text-[#ffffff] bg-[#008AE9] no-underline rounded-[20px] font-bold text-[24px] box-shadow-left"
            >¡Dona Ahora!</a
          >
        </div>
      </div>
      <div
        class="md:w-1/3 w-[280px] md:text-[20px] text-[16px] font-semibold text-[#ffffff] md:mb-0 mb-[30px] md:pl-0 pl-[25px]"
      >
        <span [innerHTML]="footerData?.footer_txt"></span>
      </div>
      <div class="md:w-1/3 w-full">
        <div class="flex items-center gap-[5px] justify-center">
          <ng-container *ngFor="let item of footerData?.social_networks">
            <a [href]="item.url" target="_blank">
              <img [src]="item.logo" class="h-[23px]" />
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
