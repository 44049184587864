import { Component, OnInit } from "@angular/core";
import { BannerComponent } from "../../components/banner/banner.component";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { SectionMetaComponent } from "./sections/section-meta/section-meta.component";
import { SectionChannelsComponent } from "./sections/section-channels/section-channels.component";
import { SectionStoresComponent } from "./sections/section-stores/section-stores.component";
import { SectionThanksYouComponent } from "./sections/section-thanks-you/section-thanks-you.component";
import { SectionRindeComponent } from "./sections/section-rinde/section-rinde.component";
import { SectionStoriesComponent } from "./sections/section-stories/section-stories.component";
import { SectionGratitudeComponent } from "./sections/section-gratitude/section-gratitude.component";
import { HomePageService } from "./services/home.service";
import { SharedDataService } from "../../layout/shared/shared-data.service";
import { FooterComponent } from "../../layout/components/footer/footer.component";
import { FooterInfoComponent } from "../../layout/components/footer-info/footer-info.component";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-home",
  standalone: true,
  imports: [
    BannerComponent,
    SectionMetaComponent,
    SectionChannelsComponent,
    SectionStoresComponent,
    SectionThanksYouComponent,
    SectionRindeComponent,
    SectionStoriesComponent,
    SectionGratitudeComponent,
    FormsModule,
    HttpClientModule,
    FooterComponent,
    FooterInfoComponent,
  ],
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  public dataHome: any;

  constructor(
    private metaService: Meta,
    private titleService: Title,
    private homeDataService: HomePageService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.homeDataService.getHome().subscribe((data) => {
      this.dataHome = data.results[0];
      this.sharedDataService.changeData(this.dataHome);

      this.metaService.updateTag({
        name: "description",
        content: this.dataHome.seo_description,
      });
      this.titleService.setTitle(this.dataHome.seo_title);
    });

    window.addEventListener("scroll", this.handleScroll);
  }

  ngOnDestroy(): void {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const button = document.querySelector(".floating-button") as HTMLElement;
    if (button) {
      if (window.scrollY > 0) {
        button.classList.add("scrolled");
        button.classList.remove("initial");
      } else {
        button.classList.add("initial");
        button.classList.remove("scrolled");
      }
    }
  };
}
